import styled from 'styled-components/macro'

const RootContainer = styled.section`
	display: flex;
	flex-direction: column;
`

const NavContainer = styled.section`
	display: flex;
	background-color: ${props => props.theme.bgc2};
	transition: background-color 0.4s;
	position: sticky;
	top: 0px;
	box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
	z-index: 2;
`

export { RootContainer, NavContainer }
