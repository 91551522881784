import styled from 'styled-components/macro'

const SIZE = {
	small: 12,
	normal: 14
}

const STATUS = {
	red: '#ce2828',
	blue: '#2073A1',
	green: '#1d9206'
}

const Text = styled.p`
	margin: 0;
	/* overflow: hidden;
  word-break: break-all; */
	font-weight: ${props => props.font};
	overflow: ${props => props.overflow};
	word-wrap: ${props => props.wordWrap};
	word-break: ${props => props.wordBreak};
	text-align: ${props => props.textAlign};
	color: ${props => props.color || props.theme.textColor};
	font-size: ${props => SIZE[props.size] || props.size}px;
`

export { Text }
