import { types, getEnv, flow, getRoot } from 'mobx-state-tree'
import { values } from 'mobx'
import { groupBy } from 'ramda'
import { format } from 'date-fns'

const Message = types
	.model('Message', {
		guid: types.identifier,
		createdBy: types.string,
		createdDate: types.string,
		authorLoginId: types.string,
		authorUserGuid: types.string,
		text: types.maybeNull(types.string),
		src: types.maybeNull(types.frozen()),
		filename: types.maybeNull(types.string),
		authorLastName: types.maybeNull(types.string),
		authorFirstName: types.maybeNull(types.string)
	})
	.actions(self => ({
		downloadVoice: flow(function*() {
			const { http } = getEnv(self)
			const { loads } = getRoot(self)

			const response = yield http.get(
				`https://route.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/tel/chatMessage/downloadFile`,
				{
					params: {
						filename: self.filename,
						loadGuid: loads.telGuid
					}
				}
			)

			if (response.ok) {
				self.src = yield response.arrayBuffer()
			}
		})
	}))
	.views(self => ({
		get groupDate() {
			return format(self.createdDate, 'MMM D, YYYY')
		},

		get messageDate() {
			return format(self.createdDate, 'hh:mm A')
		}
	}))

const Messages = types
	.model('Messages', {
		list: types.optional(types.map(Message), {})
	})
	.actions(self => ({
		sendMessage: flow(function*(bodyToSend: string | Blob) {
			const { http } = getEnv(self)
			const { auth, loads } = getRoot(self)
			const formData = new FormData()
			const isVoice = bodyToSend instanceof Blob
			const defaultData = {
				authorUserGuid: auth.user_guid,
				telGuid: loads.telGuid,
				text: isVoice ? '' : bodyToSend
			}

			formData.append('file', isVoice ? bodyToSend : null)
			formData.append('data', JSON.stringify(defaultData))

			const response = yield http.post(
				`https://route.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/tel/chatMessage`,
				formData
			)

			if (response.ok) {
				self.list.put(yield response.json())
			}
		}),

		load: flow(function*() {
			const { http } = getEnv(self)
			const { loads } = getRoot(self)

			const response = yield http.get(
				`https://route.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/tel/chatMessage/list`,
				{
					params: {
						loadGuid: loads.telGuid
					}
				}
			)

			if (response.ok) {
				const data = yield response.json()

				data.forEach(message => self.list.put(message))
			}
		}),

		clearList() {
			self.list.clear()
		}
	}))
	.views(self => ({
		get $list() {
			return groupBy(({ createdDate }) => {
				const date = new Date(createdDate)

				return `${date.getFullYear()}-${date.getDate()}`
			}, values(self.list))
		}
	}))

export { Messages }
