// constants
import { TOKEN_NAME } from '../constants'
// utils
import { queryParams, is } from './global'
// //////////////////////////////////////////////////

type IOptions = {
	params?: {},
	headers?: {},
	signal?: AbortSignal
}

const baseOptions: RequestInit = {
	headers: new Headers({
		Accept: 'application/json',
		'Content-Type': 'application/json'
	}),
	redirect: 'follow',
	mode: 'same-origin',
	cache: 'force-cache'
}

class Http {
	defaults = baseOptions

	async _request(
		method: string,
		endpoint: string,
		body,
		options: IOptions = {}
	) {
		const url = this.makeUrl(endpoint, options)
		const headers = new Headers([
			...this.defaults.headers,
			...Object.entries(options.headers || {})
		])
		const request: RequestInit = {
			method,
			headers,
			signal: options.signal
		}

		if (body) {
			if (body instanceof FormData || body instanceof URLSearchParams) {
			  if (!body.has('isMobile')) {
			    body.append('isMobile', true)
			  }
				request.body = body
				headers.delete('Content-Type')
			} else {
			  body.isMobile = body.isMobile !== undefined ? body.isMobile : true
			  request.body = JSON.stringify(body)
			}
		}

		const response = await fetch(url, request)

		return this.checkStatus(response)
	}

	get(endpoint: string, options: IOptions): Promise<Response> {
		return this._request('GET', endpoint, undefined, options)
	}

	post(endpoint: string, body, options: IOptions): Promise<Response> {
		return this._request('POST', endpoint, body, options)
	}

	put(endpoint: string, body, options: IOptions): Promise<Response> {
		return this._request('PUT', endpoint, body, options)
	}

	patch(endpoint: string, body, options: IOptions): Promise<Response> {
		return this._request('PATCH', endpoint, body, options)
	}

	delete(endpoint: string, options: IOptions): Promise<Response> {
		return this._request('DELETE', endpoint, undefined, options)
	}

	set token(token: string | undefined) {
		token
			? this.defaults.headers.set('Authorization', `Bearer ${token}`)
			: this.defaults.headers.delete('Authorization')
	}

	params(options: IOptions) {
		let params = ''

		if (options.params) {
			params = `?${queryParams(options.params)}`
			delete options.params
		}

		return params
	}

	makeUrl(endpoint: string, options: IOptions) {
		if (endpoint.startsWith('http://') || endpoint.startsWith('https://')) {
			return `${endpoint}${this.params(options)}`
		}

		return `${process.env.REACT_APP_BASE_API_URL}${endpoint}${this.params(
			options
		)}`
	}

	set cookie(token: string | undefined) {
		document.cookie = token
			? `${TOKEN_NAME}=${token};expires=Wed, 30 Dec 2020 22:00:00 GMT;path=/;SameSite=strict;${
					process.env.NODE_ENV === 'production' ? 'secure' : ''
			  }`
			: `${TOKEN_NAME}=;expires=-1`
	}

	checkStatus(response: Response) {
		if (is(response.status, 401)) {
			const { auth } = window.STORES

			// TODO: Display notification for user about expired session ?
			auth.isLogin && auth.doLogout()
		}

		return response
	}
}

export { Http }
