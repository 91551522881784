import styled, { css } from 'styled-components/macro'

const flex = css`
	display: flex;
`
const SpaceBetween = css`
	justify-content: space-between;
`

const SpaceCenter = css`
	justify-content: center;
`

const AlignCenter = css`
	align-items: center;
`

const layout = css`
	${props => props.spaceBtw && SpaceBetween};
	${props => props.spaceCenter && SpaceCenter};
	${props => props.alignCenter && AlignCenter};
`

const Row = styled.div`
	display: flex;
	${layout};
`

const Column = styled.div`
	${flex};
	${layout};
	flex-direction: column;
`

export { Row, Column }
