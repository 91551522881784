const lightTheme = {
	name: 'light',
	bodyBgc: 'white',
	bgc2: '#f3f3f3',
	bgc3: '#d8d8d8',
	bgc4: 'white',
	buttonBgc: '#ce2828',
	textColor: '#262626',
	textColor2: '#868686',
	textColor3: '#4a4a4a',
	textColor4: '#4a4a4a',
	textColor5: '#262626',
	textColor6: '#353535',
	textColor7: '#353535',
	iconDash: 'white',
	iconBgc: '#353535',
	textColorAlt: '#2073a1',
	loadNumber: '#2073a1',
	arrowIcon: '#4a4a4a',
	progressBgc: '#f3f3f3',
	modalOverlayBgc: 'rgba(134, 134, 134, 0.6)',
	boxShadow: '#eee'
}

const darkTheme = {
	...lightTheme,
	name: 'dark',
	bodyBgc: '#353535',
	bgc2: '#515151',
	bgc3: '#707070',
	bgc4: '#515151',
	iconDash: 'white',
	textColor: 'white',
	textColor2: '#d8d8d8',
	textColor3: 'white',
	textColor4: '#d8d8d8',
	textColor5: '#353535',
	textColor6: '#d8d8d8',
	textColor7: 'white',
	textColorAlt: '#d8d8d8',
	textKeyColor: '#d8d8d8',
	iconBgc: 'white',
	loadNumber: 'white',
	arrowIcon: '#d4d4d4',
	progressBgc: '#d4d4d4',
	boxShadow: '#1f1e1e'
}

export { lightTheme, darkTheme }
