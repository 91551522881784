import { createGlobalStyle } from 'styled-components/macro'

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: ${props => props.theme.bodyBgc};
    transition: background-color 0.4s;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  }

  .date-border {
    border-radius: 3px;
    border: 1px solid #b1b1b1;
    padding: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #353535;
  }

  .documents-required .documents-icon {
    g, path {
      fill: #ce2828;
    }
  }

  .documents- .documents-icon {
    g, path {
      fill: #2272a5;
    }
  }

  .documents-disabled .documents-icon {
    g, path {
      fill: #9b9b9b;
    }
  }

  .icon-size--small {
    width: 30px;
    height: 30px;
  }

  .event-status--delivered {
    .circle {
      fill: #1d9206;
    }
  }

  // TODO: remove
  .event-status--true {
    .circle {
      fill: #1d9206;
    }
  }

  // TODO: remove
  .event-status--false {
    .circle {
      fill: #2073a1;
    }
  }

  .event-status--pickup {
    .circle {
      fill: #2073a1;
    }
  }

  .event-status--drop {
    .circle {
      fill: #2073a1;
    }
  }
`
