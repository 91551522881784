import styled, { css } from 'styled-components/macro'

const Button = styled.button`
	border: none;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 14px;
	color: #fff;
	background-color: ${props => props.theme.buttonBgc};
	padding: 12px;

	${props =>
		props.icon &&
		css`
			background-color: initial;
			padding: 0;
		`};
`

export { Button }
