import styled from 'styled-components/macro'

import search from '../../images/icons/search.svg'

const Input = styled.input`
	border-radius: 3px;
	background-color: ${props => props.theme.bgc4};
	border: solid 1px #b1b1b1;
	padding-bottom: 13px;
	padding-top: 13px;
	padding-left: 16px;
	font-weight: normal;
	font-size: 12px;
	color: ${props => props.theme.textColor};
	caret-color: #930000;

	&::placeholder {
		font-weight: normal;
		font-size: 12px;
		color: #868686;
	}
`

const Search = styled(Input)`
	margin-left: 16px;
	margin-right: 16px;
	margin-top: 10px;
	margin-bottom: 10px;
	background-image: url(${search});
	background-repeat: no-repeat;
	background-position: 16px 12px;

	&:focus {
		background-image: none;
	}
`

export { Input, Search }
