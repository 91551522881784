import React from 'react'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components/macro'
// src
import { Routes } from './routes'
import { is, ErrorBoundary } from './utils'
import { lightTheme, darkTheme } from './ui/theme'
import { RootContainer, GlobalStyles } from './ui/core'
// //////////////////////////////////////////////////

const App = ({ theme, auth }) => (
	<ErrorBoundary>
		<ThemeProvider theme={is(theme.selected, 'light') ? lightTheme : darkTheme}>
			<RootContainer>
				<GlobalStyles />
				<Routes auth={auth} />
			</RootContainer>
		</ThemeProvider>
	</ErrorBoundary>
)

const hocs = compose(
	inject('theme', 'auth'),
	withRouter,
	observer
)

export default hocs(App)
