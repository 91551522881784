import React from 'react'
import styled, { css } from 'styled-components/macro'
import { NavLink } from 'react-router-dom'

const NavTab = styled(({ isDarkTheme, ...props }) => (
	/* Its fix for react warning */ <NavLink {...props} />
))`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	min-width: 50px;
	flex: 1;
	transition: background-color 0.3s ease;

	&:not(.active) {
		${props =>
			props.isDarkTheme &&
			css`
				path,
				circle {
					stroke-width: 0px;
					fill: whitesmoke;
					stroke: whitesmoke;
				}
			`};
	}

	&.active {
		background-color: #930000;

		path,
		circle {
			fill: ${props => props.theme.iconDash};
			stroke: ${props => props.theme.iconDash};
		}
	}

	&[disabled] {
		pointer-events: none;
	}
`

export { NavTab }
