import {
	T,
	or,
	add,
	not,
	cond,
	prop,
	isNil,
	propEq,
	pathOr,
	equals,
	reduce,
	always,
	isEmpty,
	complement
} from 'ramda'
// constants
import {
	OS,
  UOMS,
  STATUSES,
  TOKEN_NAME,
	FIELD_NAME_WEIGHT,
  FIELD_NAME_QUANTITY,
	FIELD_NAME_WEIGHT_TYPE,
	FIELD_NAME_PACKAGE_TYPE,
} from '../constants'
// //////////////////////////////////////////////////

const { is } = Object
const isNotNil = complement(isNil)
const notEquals = complement(equals)
const notIs = (a, b) => not(is(a, b))
const isNotEmpty = complement(isEmpty)
const isNilOrEmpty = (value: any) => isNil(value) || isEmpty(value)
const isNotNilAndNotEmpty = (value: any) => isNotNil(value) && isNotEmpty(value)
const toUpperFirst = (string: string) =>
	string.replace(string[0], string[0].toUpperCase())

function timeout(time: number): Promise<number> {
	return new Promise(resolve => setTimeout(() => resolve(time), time))
}

const noop = () => {}

function getCookie(cookies: string): string | undefined {
	const cookie = {}
	cookies.split(';').forEach(item => {
		const [key, value] = item.split('=')
		if (value) cookie[key.trim()] = value.trim()
	})
	return cookie[TOKEN_NAME]
}

export const toFixed = (number: number, digits: number = 2) => {
	if (is(Number, number)) {
		return number.toFixed(digits)
	}
	return number
}

const date = new Date()
const FIRST_DAY = new Date(date.getFullYear(), date.getMonth(), 1)
const LAST_DAY = new Date(date.getFullYear(), date.getMonth() + 1, 0)

function getStatus(rate: Object = {}, statusTel: string) {
	const { status } = rate
	switch (status) {
		case STATUSES.RATE_PENDING:
		case STATUSES.RATE_CANCELED:
		case STATUSES.RATE_DECLINED:
			return status
		default:
			return statusTel
	}
}

const fromKgsToPounds = (value: number) => (value * 2.2046).toFixed(4)

const fromPoundsToKgs = (value: number) => (value / 2.2046).toFixed(4)

const fromKgsToMetricTons = (value: number) => (value / 1000).toFixed(4)

const fromMetricTonsToKgs = (value: number) => (value * 1000).toFixed(4)

const fromKgsToShortTons = (value: number) => (value / 907.18474).toFixed(4)

const fromShortTonsToKgs = (value: number) => (value * 907.18474).toFixed(4)

const weightToKgs = cond([
	[
		propEq('from', UOMS.UOM_KILOGRAM),
		(options: Object) => prop('value', options)
	],
	[
		propEq('from', UOMS.UOM_POUND),
		(options: Object) => fromPoundsToKgs(prop('value', options))
	],
	[
		propEq('from', UOMS.UOM_METRIC_TON),
		(options: Object) => fromMetricTonsToKgs(prop('value', options))
	],
	[
		propEq('from', UOMS.UOM_TON_US),
		(options: Object) => fromShortTonsToKgs(prop('value', options))
	],
	[T, always(0)]
])

const kgsToWeight = cond([
	[propEq('to', UOMS.UOM_KILOGRAM), (options: Object) => prop('value', options)],
	[
		propEq('to', UOMS.UOM_POUND),
		(options: Object) => fromKgsToPounds(prop('value', options))
	],
	[
		propEq('to', UOMS.UOM_METRIC_TON),
		(options: Object) => fromKgsToMetricTons(prop('value', options))
	],
	[
		propEq('to', UOMS.UOM_TON_US),
		(options: Object) => fromKgsToShortTons(prop('value', options))
	],
	[T, always(0)]
])

const calcWeightFromTo = (options: Object) => {
	const { from, to, value } = options
	const fromValue = or(from, UOMS.UOM_KILOGRAM)
	const toValue = or(to, UOMS.UOM_KILOGRAM)
	const valueToConvert = or(value, 0)
	const kgs = weightToKgs({
		from: fromValue,
		value: valueToConvert
	})
	return toFixed(
		kgsToWeight({
			value: kgs,
			to: toValue
		}),
		4
	)
}

const calcItemsTotalWeightWithoutQty = reduce(
	(acc: Object, item: Object): Object => {
		let itemPickupWeight = pathOr(0, [FIELD_NAME_WEIGHT], item)
		const itemWeightType = pathOr(UOMS.UOM_KILOGRAM, [FIELD_NAME_WEIGHT_TYPE], item)
		if (isEmpty(acc)) {
			return {
				[FIELD_NAME_WEIGHT]: itemPickupWeight,
        [FIELD_NAME_WEIGHT_TYPE]: itemWeightType
			}
		}
		if (isNil(itemPickupWeight)) {
			return acc
		}
		const accWeightType = pathOr(UOMS.UOM_KILOGRAM, [FIELD_NAME_WEIGHT_TYPE], acc)
		const accPickupWeight = prop(FIELD_NAME_WEIGHT, acc)
		if (notEquals(itemWeightType, accWeightType)) {
			itemPickupWeight = calcWeightFromTo({
				to: accWeightType,
				from: itemWeightType,
				value: itemPickupWeight
			})
		}
		const resultWeight = add(accPickupWeight, itemPickupWeight)
		return {
			[FIELD_NAME_WEIGHT_TYPE]: accWeightType,
			[FIELD_NAME_WEIGHT]: resultWeight.toFixed(2)
		}
	},
	{}
)

const calculateTotalQuantity = reduce((acc: Object, item: Object): Object => {
	const itemQuantity = pathOr(0, [FIELD_NAME_QUANTITY], item)
	const itemPackageType = pathOr('Pieces', [FIELD_NAME_PACKAGE_TYPE], item)
	if (isEmpty(acc)) {
		return {
			[FIELD_NAME_QUANTITY]: itemQuantity,
			[FIELD_NAME_PACKAGE_TYPE]: itemPackageType
		}
	}
	if (isNil(itemQuantity)) {
		return acc
	}
	let accPackageType = pathOr('Pieces', [FIELD_NAME_PACKAGE_TYPE], acc)
	const accItemQuantity = prop(FIELD_NAME_QUANTITY, acc)
	if (notEquals(itemPackageType, accPackageType)) {
		accPackageType = 'Pieces'
	}
	const resultQuantity = add(accItemQuantity, itemQuantity)
	return {
		[FIELD_NAME_PACKAGE_TYPE]: accPackageType,
		[FIELD_NAME_QUANTITY]: resultQuantity.toFixed(1)
	}
}, {})

const getRateByDriver = (rate: {}, driverGuid: string) => {
	const isPrimary = is(rate.fleetAssignment.primaryDriverGuid, driverGuid)
	const amount = isPrimary ? rate.primaryDriverTotal : rate.secondaryDriverTotal
	return amount.toFixed()
}

function triggerDownload(url: string, fileName: string) {
	const a = document.createElement('a')
	a.href = url
	a.download = fileName
	document.body.appendChild(a)
	a.click()
	a.remove()
	URL.revokeObjectURL(url)
}

function queryParams(params: object) {
	return Object.keys(params)
		.map(key => {
			if (Array.isArray(params[key])) {
				return params[key]
					.map(value => encodeURIComponent(key) + '=' + encodeURIComponent(value))
					.join('&')
			}
			return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
		})
		.join('&')
}

const getMobileOperatingSystem = () => {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera
	// Windows Phone must come first because its UA also contains 'Android'
	if (/windows phone/i.test(userAgent)) return OS.WINDOWS_PHONE
	if (/android/i.test(userAgent)) return OS.ANDROID
	// iOS detection from: http://stackoverflow.com/a/9039885/177710
	if (/iPad|iPhone|iPod/.test(userAgent) && not(window.MSStream)) return OS.IOS
	return OS.OTHER
}

export {
	is,
	noop,
	notIs,
	timeout,
	isNotNil,
	LAST_DAY,
	notEquals,
	getStatus,
	getCookie,
	FIRST_DAY,
	isNotEmpty,
	queryParams,
	isNilOrEmpty,
	toUpperFirst,
	getRateByDriver,
	triggerDownload,
	isNotNilAndNotEmpty,
	calculateTotalQuantity,
	getMobileOperatingSystem,
	calcItemsTotalWeightWithoutQty
}
