import styled from 'styled-components/macro'

import arrow from '../../images/icons/arrow-down.svg'

const Option = styled.option`
	font-weight: normal;
	font-size: 14px;
	color: ${({ color }) => color};
	padding: 16px 8px;
`

const CustomOption = styled(Option)`
	cursor: pointer;
	padding: 8px;

	span {
		color: ${props => props.theme.textColor};
	}
`.withComponent('div')

const Select = styled.select`
	width: 100%;
	appearance: none;
	border-radius: 3px;
	background: url(${arrow}) no-repeat;
	background-position: calc(100% - 10px);
	border: 1px solid #b1b1b1;
	background-color: ${props => props.theme.bgc4};
	font-weight: normal;
	font-size: 12px;
	padding: 16px 13px;

	color: ${({ theme }) => theme.textColor};

	&:focus {
		border: 1px solid #930000;
		outline: none;
	}
`

const CustomSelect = styled(Select)`
	background: initial;
	width: initial;
	padding: 13px;
`.withComponent('section')

export { Select, Option, CustomOption, CustomSelect }
