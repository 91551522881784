const ROUTES = {
	loads: '/loads',
	login: '/login',
	profile: '/profile',
	messages: '/messages',
	payments: '/payments',
	load: `/loads/:loadId`,
	load_none: `/loads/none`,
	message: `/messages/:loadId`,
	passwordReset: '/password/reset',
	passwordComfirm: '/new_password',
	load_event: `/loads/:loadId/:eventId`,
	load_documents: `/loads/:loadId/documents`,
	load_event_documents: `/loads/:loadId/:eventId/documents`
}

const STATUSES = {
	// TEL
	TEL_BOOKED: 'BOOKED',
	TEL_DELIVERED: 'DELIVERED',
	TEL_IN_TRANSIT: 'IN_TRANSIT',
	// Rate
	RATE_PENDING: 'PENDING',
	RATE_DECLINED: 'DECLINED',
	RATE_CANCELED: 'CANCELED',
	RATE_ACCEPTED: 'ACCEPTED'
}

const METRIC_SYSTEM = 'metric'
const IMPERIAL_SYSTEM = 'imperial'
const DEFAULT_UOM_SYSTEM = METRIC_SYSTEM
const UOMS = {
	UOM_POUND: 'LB',
	UOM_KILOGRAM: 'KG',
	UOM_TON_US: 'TON_US',
	UOM_METRIC_TON: 'METRIC_TON'
}

// TEMPERATURE
const TEMPERATURE_TYPE_DIVIDE_NUMBER = 0 // hot/cold divider based on ℃
const UOM_FAHRENHEIT = 'Fahrenheit'
const UOM_CELSIUS = 'Celsius'

const UOM_FAHRENHEIT_SYMBOL = '℉'
const UOM_CELSIUS_SYMBOL = '℃'
const UOM_DEGREE_SYMBOL = '°'

const UOM_SYSTEM_MAP = {
	[METRIC_SYSTEM]: {
		temperature: UOM_CELSIUS
	},
	[IMPERIAL_SYSTEM]: {
		temperature: UOM_FAHRENHEIT
	}
}

const TEMPERATURE_SYMBOL_MAP = {
	[UOM_FAHRENHEIT]: UOM_FAHRENHEIT_SYMBOL,
	[UOM_CELSIUS]: UOM_CELSIUS_SYMBOL,
	defaultTypeSymbol: UOM_DEGREE_SYMBOL
}

// CONFIGS
const DRIVER_OUT_DUTY_REASON = 'driver_outOfDutyReason'
const COMMUNICATION_NOTE_TYPE = 'communication_noteType'
const COMMUNICATION_DOCUMENT_TYPE = 'communication_documentType'
const COMMUNICATION_REASON_CODE = 'communication_statusReasonCode'
const GENERAL_REASON_CODE = 'general_distanceCalculator_googleMaps_apiKey'
const COMMUNICATION_DECLINE_REASON = 'communication_tenderDeclineReasonCode'
const GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM = 'general_uomCalculator_defaultUomSystem'

const TOKEN_NAME = 'amous-mobile-token'

const LOAD_ITEM_HEIGHT = 125

export const FIELD_NAME_WEIGHT = 'weight'
export const FIELD_NAME_WEIGHT_TYPE = 'weightType'
export const FIELD_NAME_QUANTITY = 'quantity'
export const FIELD_NAME_PACKAGE_TYPE = 'packageType'

const OS = {
	IOS: 'iOS',
	OTHER: 'Unknown',
	ANDROID: 'Android',
	WINDOWS_PHONE: 'Windows Phone'
}

export {
	OS,
	UOMS,
	ROUTES,
	STATUSES,
	TOKEN_NAME,
	UOM_CELSIUS,
	UOM_FAHRENHEIT,
	UOM_SYSTEM_MAP,
	LOAD_ITEM_HEIGHT,
	DEFAULT_UOM_SYSTEM,
	UOM_CELSIUS_SYMBOL,
	GENERAL_REASON_CODE,
	UOM_FAHRENHEIT_SYMBOL,
	DRIVER_OUT_DUTY_REASON,
	TEMPERATURE_SYMBOL_MAP,
	COMMUNICATION_NOTE_TYPE,
	COMMUNICATION_REASON_CODE,
	COMMUNICATION_DOCUMENT_TYPE,
	COMMUNICATION_DECLINE_REASON,
	TEMPERATURE_TYPE_DIVIDE_NUMBER,
	GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM
}
