import { types, getEnv, flow, getRoot } from 'mobx-state-tree'
import { when, reaction } from 'mobx'
import { indexBy, prop } from 'ramda'

import {
	GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
	COMMUNICATION_REASON_CODE,
	COMMUNICATION_NOTE_TYPE,
	COMMUNICATION_DOCUMENT_TYPE,
	COMMUNICATION_DECLINE_REASON,
	DRIVER_OUT_DUTY_REASON,
	GENERAL_REASON_CODE
} from '../constants'
import { is } from '../utils'

const Option = types.model('Option', {
	displayedValue: '',
	guid: types.identifier,
	parentGuid: types.maybeNull(types.string),
	storedValue: ''
})

const Dropdown = types.model('Dropdown', {
	guid: types.identifier,
	name: '',
	options: types.optional(types.array(Option), [])
})

const StatusCode = types.model('StatusCode', {
	guid: types.identifier,
	parentGuid: types.maybeNull(types.string),
	displayValue: types.string,
	reasonCodes: types.maybeNull(types.string),
	statusCode: types.string,
	statusType: types.maybeNull(types.string)
})

const GoogleMapAPIKey = types.model('GoogleMapAPIKey', {
	value: types.maybeNull(types.string),
	inheritedValue: types.maybeNull(types.string),
	name: types.maybeNull(types.string)
})

const Configs = types
	.model('Configs', {
		dropdowns: types.optional(types.array(Dropdown), []),
		statusCodeList: types.optional(types.array(StatusCode), []),
		googleMapAPIKey: types.optional(GoogleMapAPIKey, {})
	})
	.actions(self => ({
		afterAttach() {
			const { auth } = getRoot(self)

			when(
				() => auth.isLogin,
				() => {
					const { loads } = getRoot(self)

					reaction(
						() => loads.detail && loads.telGuid,
						isTrue => {
							// Fix when logout
							if (isTrue) {
								// Load status codes on change load guids
								self.cleanConfigs()
								self.statusReasonCode()
								self.driverOnDutyCode()
								self.generalConfig()
								self.statusCode()
							}
						}
					)
				}
			)
		},

		generalConfig: flow(function*() {
			const { http } = getEnv(self)
			const { auth } = getRoot(self)
			const { enterpriseGuid } = auth

			const response = yield http.get(
				`https://configuration.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/configs`,
				{
					params: {
						enterpriseGuid,
						names: [GENERAL_REASON_CODE, GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM].join(',')
					}
				}
			)

			if (response.ok) {
				const body = yield response.json()
				const configs = indexBy(prop('name'), body.configs)

				self.googleMapAPIKey = configs[GENERAL_REASON_CODE]
				self.uomConfig = configs[GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM]
			}
		}),

		statusCode: flow(function*() {
			const { http } = getEnv(self)
			const { loads } = getRoot(self)
			const { enterpriseGuid } = loads.detail

			const response = yield http.get(
				`https://configuration.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/statusCode/list`,
				{
					params: {
						enterpriseGuid
					}
				}
			)

			if (response.ok) self.statusCodeList = yield response.json()
		}),

		statusReasonCode: flow(function*() {
			const { http } = getEnv(self)
			const { loads } = getRoot(self)
			const { enterpriseGuid } = loads.detail

			const response = yield http.get(
				`https://configuration.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/configs`,
				{
					params: {
						enterpriseGuid,
						names: `${COMMUNICATION_REASON_CODE},${COMMUNICATION_NOTE_TYPE},${COMMUNICATION_DECLINE_REASON},${COMMUNICATION_DOCUMENT_TYPE},${DRIVER_OUT_DUTY_REASON}`
					}
				}
			)

			if (response.ok) {
				const { dropdowns } = yield response.json()

				self.dropdowns = [...self.dropdowns, ...dropdowns]
			}
		}),

		driverOnDutyCode: flow(function*() {
			const { http } = getEnv(self)
			const { auth } = getRoot(self)
			const { enterpriseGuid } = auth

			const response = yield http.get(
				`https://configuration.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/configs`,
				{
					params: {
						enterpriseGuid,
						names: DRIVER_OUT_DUTY_REASON
					}
				}
			)

			if (response.ok) {
				const { dropdowns } = yield response.json()

				self.dropdowns = [...self.dropdowns, ...dropdowns]
			}
		}),

		cleanConfigs() {
			self.dropdowns.length = 0
			self.statusCodeList.length = 0
		}
	}))
	.views(self => ({
		get apiKey() {
			return self.googleMapAPIKey.value || self.googleMapAPIKey.inheritedValue
		},

		get driverOutOfDutyReasons() {
			return self.dropdowns.find(({ name }) => is(name, DRIVER_OUT_DUTY_REASON))
		}
	}))

export { Configs }
