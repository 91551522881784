import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { compose, branch, renderComponent, defaultProps } from 'recompose'
import { inject, observer } from 'mobx-react'

import { notIs } from '../utils'
import { ROUTES } from '../constants'

import { Navigation } from '../components/common'

const Login = lazy(() =>
	import(/* webpackChunkName: 'Login' */ '../components/auth/Login')
)

const ResetPassword = lazy(() =>
	import(/* webpackChunkName: 'ResetPassword' */ '../components/auth/ResetPassword')
)

const CreateNewPassword = lazy(() =>
	import(/* webpackChunkName: 'CreateNewPassword' */ '../components/auth/CreateNewPassword')
)

const Load = lazy(() =>
	import(/* webpackChunkName: 'Load' */ '../components/loads/load')
)

const NoLoad = lazy(() =>
	import(/* webpackChunkName: 'NoLoad' */ '../components/loads/no-load')
)

const Event = lazy(() =>
	import(/* webpackChunkName: 'Event' */ '../components/loads/event')
)

const Payments = lazy(() =>
	import(/* webpackChunkName: 'Payments' */ '../components/payments')
)

const LoadsList = lazy(() =>
	import(/* webpackChunkName: 'LoadsList' */ '../components/loads/list')
)

const Documents = lazy(() =>
	import(/* webpackChunkName: 'Documents' */ '../components/loads/documents')
)

const Message = lazy(() =>
	import(/* webpackChunkName: 'Message' */ '../components/messages')
)

const MessagesList = lazy(() =>
	import(/* webpackChunkName: 'MessagesList' */ '../components/messages/List')
)

const Profile = lazy(() =>
	import(/* webpackChunkName: 'Profile' */ '../components/profile')
)

const Layout = ({ children }) => (
	<>
		<Navigation />
		<Suspense fallback={null}>{children}</Suspense>
	</>
)

export default ({ auth }) => (
	<Suspense fallback={null}>
		<Switch>
			<CustomRoute
				path={ROUTES.login}
				redirect={ROUTES.loads}
				component={Login}
				isPrivate={false}
			/>
			<CustomRoute
				path={ROUTES.passwordReset}
				component={ResetPassword}
				redirect={ROUTES.loads}
				isPrivate={false}
			/>
			<CustomRoute
				path={ROUTES.passwordComfirm}
				component={CreateNewPassword}
				redirect={ROUTES.loads}
				isPrivate={false}
			/>
			<Layout>
				<Switch>
					<CustomRoute path={ROUTES.load_event_documents} component={Documents} />
					<CustomRoute path={ROUTES.load_documents} component={Documents} />
					<CustomRoute path={ROUTES.load_none} component={NoLoad} />
					<CustomRoute path={ROUTES.load} component={Load} />
					<CustomRoute path={ROUTES.load_event} component={Event} />
					{auth.canViewPayments && (
						<CustomRoute path={ROUTES.payments} component={Payments} />
					)}
					<CustomRoute path={ROUTES.loads} component={LoadsList} />
					<CustomRoute path={ROUTES.messages} component={MessagesList} />
					<CustomRoute path={ROUTES.message} component={Message} />
					<CustomRoute path={ROUTES.profile} component={Profile} />
					<RedirectTo redirect={ROUTES.login} />
				</Switch>
			</Layout>
		</Switch>
	</Suspense>
)

const RedirectTo = props => <Redirect to={props.redirect} />

const CustomRoute = compose(
	defaultProps({
		isPrivate: true,
		redirect: ROUTES.login,
		exact: true
	}),
	inject('auth'),
	observer,
	branch(
		({ auth, isPrivate }) => notIs(auth.isLogin, isPrivate),
		renderComponent(RedirectTo)
	)
)(Route)
