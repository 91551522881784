import React, { Component } from 'react'
import { withRouter, matchPath } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { not } from 'ramda'
import { compose } from 'recompose'

import { NavContainer } from '../../ui/core'
import { NavTab } from '../../ui/components/Navigation'
import { ROUTES } from '../../constants'
import { is } from '../../utils'

// Icons
import { ReactComponent as RoutesIcon } from '../../images/icons/start-and-finish.svg'
import { ReactComponent as PaymentsIcom } from '../../images/icons/payments.svg'
import { ReactComponent as RouteIcon } from '../../images/icons/route.svg'
import { ReactComponent as MessagesIcon } from '../../images/icons/messages.svg'
import { ReactComponent as ProfileIcon } from '../../images/icons/user-avatar.svg'

// TODO: Add indicator

const hocs = compose(
	withRouter,
	inject('loads', 'theme', 'auth'),
	observer
)

class Navigation extends Component {
	onActiveLoad = (_, location) => {
		const { loads } = this.props

		if (is(location.pathname, ROUTES.load_none)) return true
		if (not(loads.active)) return false
		if (matchPath(location.pathname, { exact: true, path: ROUTES.load }))
			return true
		if (matchPath(location.pathname, { exact: true, path: ROUTES.load_event }))
			return true
		if (
			matchPath(location.pathname, {
				exact: true,
				path: ROUTES.load_event_documents
			})
		)
			return true
	}

	onLoadsOrEvent = (_, location) => {
		if (not(this.onActiveLoad(_, location))) {
			if (matchPath(location.pathname, { exact: true, path: ROUTES.loads }))
				return true
			if (matchPath(location.pathname, { exact: true, path: ROUTES.load_event }))
				return true
			if (
				matchPath(location.pathname, {
					exact: true,
					path: ROUTES.load_event_documents
				})
			)
				return true
			if (matchPath(location.pathname, { exact: true, path: ROUTES.load }))
				return true
		}

		return false
	}

	render() {
		const { loads, theme, auth } = this.props
		const { active } = loads
		const isDarkTheme = is(theme.selected, 'dark')

		return (
			<NavContainer>
				<NavTab
					isActive={this.onActiveLoad}
					to={active ? `/loads/${active.guid}` : ROUTES.load_none}
					isDarkTheme={isDarkTheme}>
					<RoutesIcon />
				</NavTab>
				{auth.canViewPayments && (
					<NavTab to={ROUTES.payments} isDarkTheme={isDarkTheme}>
						<PaymentsIcom />
					</NavTab>
				)}
				<NavTab
					isActive={this.onLoadsOrEvent}
					to={ROUTES.loads}
					isDarkTheme={isDarkTheme}>
					<RouteIcon />
				</NavTab>
				<NavTab to={ROUTES.messages} isDarkTheme={isDarkTheme}>
					<MessagesIcon />
				</NavTab>
				<NavTab to={ROUTES.profile} isDarkTheme={isDarkTheme}>
					<ProfileIcon />
				</NavTab>
			</NavContainer>
		)
	}
}

export default hocs(Navigation)
