import styled from 'styled-components/macro'
// constants
import { STATUSES } from '../../constants'
// //////////////////////////////////////////////////

const STATUS = {
	[STATUSES.TEL_BOOKED]: '#2073a1',
	[STATUSES.RATE_PENDING]: '#2073a1',
	[STATUSES.RATE_DECLINED]: '#ce2828',
	[STATUSES.RATE_CANCELED]: '#868686',
	[STATUSES.RATE_ACCEPTED]: '#1d9206',
	[STATUSES.TEL_DELIVERED]: '#1d9206',
	[STATUSES.TEL_IN_TRANSIT]: '#2073a1'
}

export const Label = styled.label`
	display: ${({ dp }) => dp || 'flex'};
	align-items: center;
	font-weight: 500;
	font-size: 14px;
	padding: 5px;
	background-color: ${({ status, bg }) => STATUS[status] || bg};
	width: max-content;
	border-radius: 5px;
	color: ${({ status, theme }) => STATUS[status] ? 'white' : theme.textColor};
	text-align: ${({ textAlign }) => textAlign};
`

export const LabelEllipsis = styled(Label)`
	max-width: ${props => props.maxWidth || '100%'};
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`
