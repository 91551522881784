import React, { Component } from 'react'
import styled from 'styled-components/macro'
// //////////////////////////////////////////////////

const Section = styled.section`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`

class ErrorBoundary extends Component {
	state = {
		hasError: false
	}

	static getDerivedStateFromError() {
		return { hasError: process.env.NODE_ENV === 'production' }
	}

	render() {
		return this.state.hasError ? (
			<Section>Happened error :(</Section>
		) : (
			this.props.children
		)
	}
}

export { ErrorBoundary }
