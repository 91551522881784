import { when } from 'mobx'
import { types, flow, getEnv, getRoot, applySnapshot } from 'mobx-state-tree'
// utils
import { is } from '../utils'
// //////////////////////////////////////////////////

const State = types.optional(
	types.model({
		state: types.optional(
			types.enumeration('state', ['pending', 'success', 'error', '']),
			''
		),
		errors: ''
	}),
	{}
)

const OutOfDutyReason = types.model('OutOfDutyReason', {
	guid: types.optional(types.identifier, ''),
	displayedValue: '',
	storedValue: '',
	dropdownOptionGuid: ''
})

const auth = {
	headers: {
		Authorization: `Basic ${btoa('mobile:secret')}`
	}
}

const DriverHours = types.model('DriverHours', {
	guid: types.optional(types.identifier, ''),
	driverGuid: '',
	offDutyHoursLeft: types.maybeNull(types.number),
	onDutyHoursLeft: types.maybeNull(types.number),
	outOfDutyReason: types.maybeNull(OutOfDutyReason),
	onDuty: types.maybeNull(types.boolean),
	version: types.maybeNull(types.number)
})

let promise

const Auth = types
	.model('Auth', {
		login: State,
		signup: State,
		reset: State,
		confirm: State,
		update: State,
		updateHours: State,
		driver_guid: types.frozen(''),
		user_guid: types.frozen(''),
		email: types.frozen(''),
		enterpriseGuid: types.frozen(''),
		firstName: types.frozen(''),
		lastName: types.frozen(''),
		phoneNumber: types.maybeNull(types.frozen(types.string)),
		password: types.frozen(''),
		driverHours: types.optional(types.frozen(DriverHours), {}),
		authorities: types.optional(types.frozen(types.array(types.string)), [])
	})
	.actions(self => ({
		afterAttach() {
			promise = when(() => self.isLogin, getRoot(self).configs.generalConfig)
		},

		beforeDestroy() {
			promise.cancel()
		},

		getSession: flow(function*(token: string) {
			const { http } = getEnv(self)

			const response = yield http.post(
				'/oauth/check_token',
				new URLSearchParams(`token=${token}`),
				auth
			)

			if (response.ok) {
				const { user_guid, authorities } = yield response.json()

				http.token = token
				self.authorities = authorities
				self.user_guid = user_guid

				return self.getDriver()
			}
		}),

		getDriverHours: flow(function*() {
			// commented due to not using for now - clarify in future if needed
			// const { http } = getEnv(self)
			// const response = yield http.get(
			// 	`https://fleet.amoustms.${
			// 		process.env.REACT_APP_BASE_API_DOMAIN
			// 	}/fleet/driver/driverHours`,
			// 	{
			// 		params: {
			// 			driverGuid: self.driver_guid
			// 		}
			// 	}
			// )
			// if (response.ok) {
			// 	self.driverHours = yield response.json()
			// 	self.login.state = 'success'
			// }
		}),

		getDriver: flow(function*() {
			const { http } = getEnv(self)

			const response = yield http.get(
				`https://fleet.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/fleet/driver`,
				{
					params: {
						userGuid: self.user_guid
					}
				}
			)

			if (response.ok) {
				const { guid, ...rest } = yield response.json()

				Object.assign(self, rest, { driver_guid: guid })
				self.getDriverHours()
				self.login.state = 'success'
			}
		}),

		doLogin: flow(function*(body) {
			const { http } = getEnv(self)
			self.login.state = 'pending'
			const response = yield http.post('/oauth/token', body, auth)
			const {
				user_guid,
				access_token,
				error_description,
				message,
				authorities
			} = yield response.json()
			if (response.ok) {
				self.login.errors = ''
				self.user_guid = user_guid
				self.password = body.password
				self.authorities = authorities
				http.token = access_token
				http.cookie = access_token
				self.getDriver()
			} else {
				self.login.state = ''
				self.login.errors = error_description || message
			}
		}),

		doReset: flow(function*(body) {
			const { http } = getEnv(self)

			self.reset.state = 'pending'

			const { ok } = yield http.post('/password/forgot', body, {
				auth: {
					// TODO: Check if can pass `auth` const
					username: 'mobile',
					password: 'secret'
				}
			})

			self.reset.state = ok ? 'success' : ''
		}),

		updatePassword: flow(function*(body) {
			const { http } = getEnv(self)

			const response = yield http.post('/password/update', {
				...body,
				userGuid: self.user_guid
			})

			if (response.ok) {
				self.password = body.newPassword

				return true
			} else {
				const { error_description, message } = yield response.json()

				return error_description || message
			}
		}),

		updateDriverHours: flow(function*(body) {
			// commented due to not using for now - clarify in future if needed
			// const { http } = getEnv(self)
			// self.updateHours.state = 'pending'
			// const response = yield http.put(
			// 	`https://fleet.amoustms.${
			// 		process.env.REACT_APP_BASE_API_DOMAIN
			// 	}/fleet/driver/driverHours`,
			// 	body
			// )
			// const data = yield response.json()
			// if (response.ok) {
			// 	self.updateHours.state = 'success'
			// 	self.driverHours = data
			// 	return true
			// } else {
			// 	self.updateHours.state = ''
			// 	self.update.errors = data.error_description || data.message
			// }
		}),

		doConfirmReset: flow(function*(body) {
			const { http } = getEnv(self)
			const params = new URLSearchParams(window.location.search)

			http.token = params.get('token')
			self.confirm.state = 'pending'

			const { ok } = yield http.post('/password/save', body)

			if (ok) {
				self.confirm.state = 'success'
				http.token = null
			} else {
				self.confirm.state = ''
			}
		}),

		doLogout() {
			const { http } = getEnv(self)
			const root = getRoot(self)

			http.cookie = undefined
			http.token = undefined
			applySnapshot(root, {
				...window.CLEAN_STORES,
				theme: root.theme.toJSON()
			})
		}
	}))
	.views(self => ({
		get isLogin() {
			return is(self.login.state, 'success')
		},

		get canViewPayments() {
			return ['TEL_INVOICE_READ', 'TEL_INVOICE_WRITE'].some(perm =>
				self.authorities.includes(perm)
			)
		},

		get canViewRate() {
			return ['TEL_RATE_READ', 'TEL_RATE_WRITE'].some(perm =>
				self.authorities.includes(perm)
			)
		},

		get fullName() {
			return `${self.firstName} ${self.lastName}`
		}
	}))

export { Auth }
