import { types } from 'mobx-state-tree'

import { is } from '../utils'

const hour = new Date().getHours()
const isNight = (hour >= 18 && hour <= 23) || (hour < 5 && hour >= 0)

const Theme = types
	.model('Theme', {
		selected: types.optional(
			types.enumeration(['light', 'dark']),
			isNight ? 'dark' : 'light'
		)
	})
	.actions(self => ({
		toggle() {
			self.selected = is(self.selected, 'light') ? 'dark' : 'light'
		}
	}))

export { Theme }
