import { ReactComponent as ModalCloseIcon } from '../../images/icons/modal-close.svg'

const defaultModalStyles = theme => ({
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: theme.modalOverlayBgc,
		zIndex: 2,
		overflow: 'auto'
	},
	content: {
		position: 'absolute',
		top: 60,
		left: '16px',
		right: '16px',
		bottom: 'initial',
		border: '1px solid rgb(204, 204, 204)',
		background: theme.bodyBgc,
		overflow: 'auto',
		borderRadius: 4,
		outline: 'none',
		padding: 10
	}
})

export { defaultModalStyles, ModalCloseIcon }
