import styled from 'styled-components/macro'
import { Input } from '.'

const Form = styled.form`
	display: flex;
	flex-direction: column;

	${Input}:not(:first-child) {
		margin-top: ${({ withoutMargin }) => !withoutMargin && '15px'};
	}
`

export { Form }
