import { types } from 'mobx-state-tree'

import { Auth } from './Auth'
import { Theme } from './Theme'
import { Messages } from './Messages'
import { Documents } from './Documents'
import { Configs } from './Configs'
import { Loads } from './Loads'

const Stores = types.model('RootStore', {
	auth: types.optional(Auth, {}),
	loads: types.optional(Loads, {}),
	theme: types.optional(Theme, {}),
	messages: types.optional(Messages, {}),
	documents: types.optional(Documents, {}),
	configs: types.optional(Configs, {})
})

export default Stores
