import { once } from 'ramda'
// global
import { is } from './global'
// //////////////////////////////////////////////////

function geolocation(): Promise<Position | PositionError> {
	return new Promise((resolve, reject) =>
		navigator.geolocation.getCurrentPosition(resolve, reject, {
			timeout: 4000,
			enableHighAccuracy: true
		})
	)
}

const loadGoogleMapScript = once((url: string) => {
	const script = document.createElement('script')

	return new Promise((resolve, reject) => {
		script.src = url
		script.async = true
		script.onload = () => {
			resolve()
			script.remove()
		}
		script.onerror = reject
		document.head.appendChild(script)
	})
})

export function findLocationValue(
	address: google.maps.GeocoderAddressComponent[],
	type: string,
	insuranceType: ?string
) {
	const { long_name = '' } =
		address.find(({ types }) =>
			types.find(_type => is(_type, type) || is(_type, insuranceType))
		) || {}

	return long_name
}

function getCurrentPosition(location: google.maps.LatLng) {
	const { geocode } = new google.maps.Geocoder()

	return new Promise((resolve, reject) => {
		geocode({ location }, ([result], status) => {
			if (is(status, 'OK')) {
				const { address_components, formatted_address } = result

				return resolve({
					city: findLocationValue(address_components, 'locality', 'postal_town'),
					state: findLocationValue(
						address_components,
						'administrative_area_level_1'
					),
					zip: findLocationValue(address_components, 'postal_code'),
					country: findLocationValue(address_components, 'country'),
					formatted_address
				})
			} else reject(status)
		})
	})
}

export { geolocation, getCurrentPosition, loadGoogleMapScript }
