import { types, getEnv, flow, getRoot } from 'mobx-state-tree'
import { isNil, last } from 'ramda'

import { OS } from '../constants'
import { triggerDownload, getMobileOperatingSystem, is } from '../utils'

function ext(documentFilename: string | null) {
	if (isNil(documentFilename)) return ''

	return last(documentFilename.split('.'))
}

const Document = types
	.model('Document', {
		guid: types.identifier,
		documentFilename: types.maybeNull(types.string),
		url: types.maybeNull(types.string),
		ext: types.string,
		size: types.maybeNull(types.number) // TODO: let API send size
	})
	.preProcessSnapshot(snapshot => ({
		...snapshot,
		ext: ext(snapshot.documentFilename)
	}))
	.actions(self => ({
		getSize: flow(function*(isIOS) {
			const { loads } = getRoot(self)
			const { http } = getEnv(self)
			const fileName = self.documentFilename

			const response = yield http.get(
				`https://route.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/tel/document/downloadFile`,
				{
					params: {
						fileName,
						primaryObjectGuid: loads.telGuid
					}
				}
			)

			if (response.ok) {
				const data = yield response.arrayBuffer()
				const blob = new Blob([data], { type: 'application/octet-stream' })
				if (isIOS) {
					const reader = new FileReader()
					reader.onload = () => {
						window.location.href = reader.result
					}
					reader.readAsDataURL(blob)
				} else {
					const url = URL.createObjectURL(blob)
					self.size = data.byteLength
					self.url = url
				}
			}
		}),
		download: flow(function*() {
			const os = getMobileOperatingSystem()

			if (is(os, OS.IOS)) {
				yield self.getSize(true)
			} else if (self.url) {
				triggerDownload(self.url, self.documentFilename)
			} else {
				yield self.getSize()
				triggerDownload(self.url, self.documentFilename)
			}
		})
	}))

const Documents = types
	.model('Documents', {
		list: types.optional(types.map(Document), {})
	})
	.actions(self => ({
		upload: flow(function*(file: File | Blob, eventGuid?: string, _body = {}) {
			const { loads } = getRoot(self)
			const { http } = getEnv(self)
			const form = new FormData()
			const body = {
				primaryObjectGuid: loads.telGuid,
				proofType: 'NONE',
				..._body
			}
			if (eventGuid) body.eventGuid = eventGuid

			form.append('file', file, _body.filename)
			form.append('data', JSON.stringify(body))

			const response = yield http.post(
				`https://route.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/tel/document`,
				form
			)

			if (response.ok) self.list.put(yield response.json())
		}),

		load: flow(function*(eventGuid?: string) {
			const { http } = getEnv(self)
			const { loads } = getRoot(self)
			const params = {
				primaryObjectGuid: loads.telGuid
			}

			if (eventGuid) params.eventGuid = eventGuid

			const response = yield http.get(
				`https://route.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/tel/document/list/mobile`,
				{
					params
				}
			)

			if (response.ok) {
				const data = yield response.json()

				data.forEach(document => self.list.put(document))
			}
		}),

		clearDocuments() {
			self.list.clear()
		}
	}))

export { Documents }
