import { format } from 'date-fns'
import { and, isNil, pathOr, clone } from 'ramda'
import React, { CSSProperties, Component } from 'react'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
// constants
import {
	STATUSES,
	TEMPERATURE_SYMBOL_MAP,
	UOM_SYSTEM_MAP,
	TEMPERATURE_TYPE_DIVIDE_NUMBER,
	DEFAULT_UOM_SYSTEM
} from '../../constants'
// images
import { ReactComponent as ArrowRightIcon } from '../../images/icons/arrow-right.svg'
import { ReactComponent as PendingLoadIcon } from '../../images/icons/pending-load.svg'
import { ReactComponent as AcceptedLoadIcon } from '../../images/icons/accepted-load.svg'
import { ReactComponent as DeclinedLoadIcon } from '../../images/icons/declined-load.svg'
import { ReactComponent as CanceledLoadIcon } from '../../images/icons/canceled-load.svg'
import { ReactComponent as DeliveredLoadIcon } from '../../images/icons/delivered-load.svg'
import { ReactComponent as InTransitLoadIcon } from '../../images/icons/in-transit-load.svg'
// ui
import {
	LoadTime,
	ListItem,
	ListItemTitle,
	LoadTimeWrapper,
	ListItemAddressWithEllipsis
} from '../../ui/components/ListItem'
import { Row, Text, Label, Column } from '../../ui/core'
// utils
import {
  is,
  isNotNil,
  normalizeTemperature,
  normalizeLocationToString,
  normalizeTemperatureRange,
} from '../../utils'
import { ReactComponent as ColdIcon } from "../../images/icons/temperature-cold.svg"
import { ReactComponent as HotIcon } from "../../images/icons/temperature-hot.svg"
import { ReactComponent as ColdDarkIcon } from "../../images/icons/temperature-cold-dark.svg"
import { ReactComponent as HotDarkIcon } from "../../images/icons/temperature-hot-dark.svg"
// //////////////////////////////////////////////////

const LOAD_ICON = {
	[STATUSES.TEL_BOOKED]: <AcceptedLoadIcon />,
	[STATUSES.RATE_PENDING]: <PendingLoadIcon />,
	[STATUSES.RATE_DECLINED]: <DeclinedLoadIcon />,
	[STATUSES.RATE_CANCELED]: <CanceledLoadIcon />,
	[STATUSES.RATE_ACCEPTED]: <AcceptedLoadIcon />,
	[STATUSES.TEL_DELIVERED]: <DeliveredLoadIcon />,
	[STATUSES.TEL_IN_TRANSIT]: <InTransitLoadIcon />
}

type Props = {
	style: CSSProperties,
	showDate?: boolean,
	navigate(guid: string): void,
	load: any
}

const PADDINGS = 20
const { innerWidth } = window
const ITEM_WIDTH = innerWidth - PADDINGS
const NO_MESSAGES_TEXT = 'No messages'

// react-window setting width: 100% which adds horizontal scroll
function fixWidth(style: CSSProperties) {
  const styleClone = clone(style)
  styleClone.width = ITEM_WIDTH
	styleClone.padding = 0
  return styleClone
}

const TemperatureBox = styled.span`
	color: ${props => props.theme.textColor};	
	margin-left: 5px;
	display: inline-flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
`

const TemperatureText = styled.span`
  margin-left: 2px;
	padding: 0;
`

const temperatureHocs = compose(
  inject('configs', 'theme'),
  observer
)

class TemperatureBlockPure extends Component {
	getTemperatureUOM() {
		const { configs } = this.props
		const { uomConfig } = configs
    const uomSystemFromValue = uomConfig && uomConfig.value && UOM_SYSTEM_MAP[uomConfig.value]
    const uomSystemFromInherited = uomConfig && uomConfig.inheritedValue && UOM_SYSTEM_MAP[uomConfig.inheritedValue]
    const systemUom = uomSystemFromValue || uomSystemFromInherited || UOM_SYSTEM_MAP[DEFAULT_UOM_SYSTEM]
		return systemUom.temperature
	}

	getTemperatureIcon(temperature) {
    const isDarkTheme = this.props.theme.selected === 'dark'
    const isCold = !isNil(temperature) && temperature < TEMPERATURE_TYPE_DIVIDE_NUMBER
    const themedColdIcon = isDarkTheme ? ColdDarkIcon : ColdIcon
    const themedHotIcon = isDarkTheme ? HotDarkIcon  : HotIcon
    return isCold ? themedColdIcon : themedHotIcon
  }

	render() {
    const {source: { temperatureLow, temperatureHigh }} = this.props
		const temperatureUOM = this.getTemperatureUOM()
		const temperatureTypeSymbol = TEMPERATURE_SYMBOL_MAP[temperatureUOM] || TEMPERATURE_SYMBOL_MAP.defaultTypeSymbol
		const temperatureRange = normalizeTemperatureRange(
			normalizeTemperature(temperatureLow, temperatureUOM),
			normalizeTemperature(temperatureHigh, temperatureUOM),
			temperatureTypeSymbol
		)
		const TemperatureIcon = this.getTemperatureIcon(temperatureLow)
		return !temperatureRange ? null : (
			<TemperatureBox>
				<TemperatureIcon />
				<TemperatureText>{temperatureRange}</TemperatureText>
			</TemperatureBox>
		)
	}
}

export const TemperatureBlock = temperatureHocs(TemperatureBlockPure)

export const LoadItem = ({ load, navigate, showDate, style }: Props) => (
	<ListItem style={fixWidth(style)} onClick={() => navigate(load.guid)}>
		<Column style={{
			padding: '0 10px',
		}}>
			{LOAD_ICON[load.getStatus]}
		</Column>
		<Column
			style={{
				padding: '5px 10px',
				flexGrow: 1,
				height: '100%',
				boxSizing: 'border-box',
				maxWidth: 'calc(100% - 70px)'
			}} >
			<Row
				style={{
					flexWrap: 'nowrap',
					maxWidth: '100%'
				}}
				spaceBtw >
				<ListItemTitle width={style.width} font="normal" size="normal">
					Load ID: {load.primaryReferenceValue}
				</ListItemTitle>
				{and(showDate, isNotNil(load.lastMessageDate)) && (
					<LoadTimeWrapper>
						<LoadTime>{format(load.lastMessageDate, 'MMM D, YYYY hh:mm A')}</LoadTime>
					</LoadTimeWrapper>
				)}
				{and(showDate, isNil(load.lastMessageDate)) && (
					<Text size="small">{NO_MESSAGES_TEXT}</Text>
				)}
			</Row>
			<Row>
				<Label
					as="span"
					style={{ marginTop: 5, marginBottom: 5 }}
					status={load.getStatus}>
					{load.getStatus}
				</Label>
				<TemperatureBlock source={load} />
			</Row>
			<ListItemAddressWithEllipsis
				size="small"
				font="normal"
				style={{
					color: is(load.getStatus, STATUSES.RATE_PENDING) ? '#2073a1' : ''
				}}>
				{normalizeLocationToString(pathOr({}, ['lastEvent', 'location'], load), {
					locationName: 1
				})}
			</ListItemAddressWithEllipsis>
			<ListItemAddressWithEllipsis
				size="small"
				font="normal"
				style={{
					color: is(load.getStatus, STATUSES.RATE_PENDING) ? '#2073a1' : ''
				}}>
				<ArrowRightIcon style={{ paddingRight: 4 }} /> <br />
				{normalizeLocationToString(pathOr({}, ['lastEvent', 'location'], load), {
					locationName: 1
				})}
			</ListItemAddressWithEllipsis>
		</Column>
	</ListItem>
)

LoadItem.defaultProps = {
	showDate: true
}