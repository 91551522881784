import React from 'react'
import { when } from 'mobx'
import { render } from 'react-dom'
import { Provider } from 'mobx-react'
import { createBrowserHistory } from 'history'
import { Router as BrowserRouter } from 'react-router-dom'
// src
import App from './App'
import Stores from './stores'
import { ROUTES } from './constants'
import { API, routesMap } from './routes'
import { Http, getCookie, timeout, is } from './utils'
// //////////////////////////////////////////////////

const history = createBrowserHistory()
const root = document.getElementById('root')
const http = new Http()
const stores = Stores.create(undefined, { http, history })

window.CLEAN_STORES = stores.toJSON()
window.STORES = stores

async function boot() {
	const routes = routesMap(stores)
	const token = getCookie(document.cookie)
	const api = new API(routes, history)

	try {
		if (token) {
			await stores.auth.getSession(token)

			if (stores.auth.isLogin) {
				const { loads } = stores
				const TIME = 800

				loads.getList('GENERAL')

				const promise = when(() => loads.active)
				const value = await Promise.race([promise, timeout(TIME)])

				if (is(value, TIME)) promise.cancel()
				else history.replace(`${ROUTES.loads}/${loads.active.guid}`)
			}
		}
	} catch (error) {
		console.error(error)
	}

	api.run(history.location)

	render(
		<BrowserRouter history={history}>
			<Provider {...stores}>
				<App />
			</Provider>
		</BrowserRouter>,
		root
	)
}

boot()
