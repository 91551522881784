import { TEMPERATURE_SYMBOL_MAP, UOM_FAHRENHEIT } from '../constants'
import { is, isNil, keys, mergeDeepRight, not, or } from 'ramda'

/**
 * Get formatted string of location address
 * @param {Object} location - standard location object
 * @returns {String|null} - returns null if location is not passed
 */
export const normalizeLocationToString = (location: Object, priority: Object) => {
	if (!location) return null
	const defaultPriorityMap = {
		locationName: 0,
		locationCode: 0,
		address1: 20,
		address2: 0,
		city: 0,
		state: 30,
		zip: 40,
		country: 50,
		comments: 0
	}
	const priorityMap = mergeDeepRight(defaultPriorityMap, priority || {})
	const fieldsValues = keys(defaultPriorityMap) //Get all possible location fields
		.filter(fieldName => priorityMap[fieldName]) //Ignore fields with zero priority
		.sort((a, b) => a - b) //Ascending order by priority
		.map(fieldName => location[fieldName]) //Get values from location object

	const result = fieldsValues.filter(item => item).join(', ') //Ignore empty values and join with comma
	return result
}

export const convertCelsiusToFahrenheit = (celsius: number) => {
	const valueIsNotValid = or(not(is(Number, celsius)), isNaN(celsius))
	return valueIsNotValid ? null : ((celsius * 9) / 5 + 32).toFixed(1)
}

/**
 * @param temperature - temperature, number or parsable string
 * @param {String} [type='Celsius'] -  Celsius of Fahrenheit
 * @returns {String|null} - returns null if temperature is invalid
 */
export const normalizeTemperature = (temperature: number, type: string) => {
	const temperatureNumber = parseFloat(temperature)
	const temperatureValue = type !== UOM_FAHRENHEIT ? temperatureNumber : convertCelsiusToFahrenheit(temperatureNumber)
	const temperatureString = (temperatureValue > 0 ? '+' : '') + temperatureValue
	return isNil(temperatureValue) ? null : temperatureString
}

/**
 *
 * @param {Number} low - first element of range
 * @param {Number} high - second element fo range
 * @param {String} [tempSymbol='°'] - temperature symbol
 * @param {String} [divider='➔'] -  String between temp values
 * @returns {string|null} - null if temperature values are both invalid
 */
export const normalizeTemperatureRange = (low: number, high: number, tempSymbol: string, divider: string) => {
	const defaultDivider = '➔'
	const defaultSymbol = TEMPERATURE_SYMBOL_MAP.defaultTypeSymbol
	const symbol = tempSymbol !== undefined ? tempSymbol : defaultSymbol
	const lowNotNil = !isNil(low) && !isNaN(low)
	const highNotNil = !isNil(high) && !isNaN(high)
	if (!lowNotNil && !highNotNil) {
		return null
	}

	let result = null
	if (lowNotNil && highNotNil) {
		result = `${low}${symbol}${divider || defaultDivider}${high}${symbol}`
	} else {
		result = `${lowNotNil ? '>' : '<'}${lowNotNil ? low : high}${symbol}`
	}
	return result
}
