import { matchPath, match as Imatch, RouteProps } from 'react-router-dom'
import { History, Location } from 'history'

import { ROUTES } from '../constants'
import { noop, is } from '../utils'

type Handler = (stores, match: Imatch) => Promise
type LoadId = { loadId: string }
type EventId = { eventId: string }

const isEmpty = (map: Map) => is(map.size, 0)
const path = (path: string, exact = true) => ({ path, exact })
const route = (pathname: string, load: Handler) => [path(pathname), load]
const withStores = stores => (load: Handler) => (match: Imatch) =>
	load(stores, match)

function messages(stores, match) {
	if (isEmpty(stores.loads.messages)) stores.loads.getList('MESSAGES')
}

function payments(stores, match) {
	if (isEmpty(stores.loads.finance)) stores.loads.getList('FINANCE')
}

function loads(stores, match) {
	if (isEmpty(stores.loads.general)) stores.loads.getList('GENERAL')
}

function loadId(stores, { params }: Imatch<LoadId>) {
	stores.loads.setTelGuid(params.loadId)
	stores.loads.getDetail(params.loadId)
}

function documents(stores, match: Imatch<LoadId & EventId>) {
	stores.loads.setTelGuid(match.params.loadId)
	stores.documents.load(match.params.eventId)
}

function messagesId(stores, match: Imatch<LoadId>) {
	stores.loads.setTelGuid(match.params.loadId)
	stores.messages.load()
}

function profile(stores) {
	stores.configs.driverOnDutyCode()
}

const routesMap = stores => {
	const inject = withStores(stores)

	return [
		route(ROUTES.load_event_documents, inject(documents)),
		route(ROUTES.load_documents, inject(documents)),
		route(ROUTES.messages, inject(messages)),
		route(ROUTES.message, inject(messagesId)),
		route(ROUTES.loads, inject(loads)),
		route(ROUTES.payments, inject(payments)),
		route(ROUTES.load_none, noop),
		route(ROUTES.load, inject(loadId)),
		route(ROUTES.load_event, inject(loadId)),
		route(ROUTES.profile, inject(profile))
	]
}

class API {
	constructor(routes: Array<[RouteProps, Handler]>, history: History) {
		this.routes = routes

		history.listen(this.run)
	}

	run = ({ pathname }: Location) => {
		for (const [config, load] of this.routes) {
			if (load) {
				const match = matchPath(pathname, config)

				if (match) return load(match)
			}
		}
	}
}

export { routesMap, API }
