import { values } from 'mobx'
import { format } from 'date-fns'
import { add, isEmpty } from 'ramda'
import { types, getEnv, flow, getRoot } from 'mobx-state-tree'
// constants
import { STATUSES, FIELD_NAME_WEIGHT, FIELD_NAME_WEIGHT_TYPE, FIELD_NAME_PACKAGE_TYPE, FIELD_NAME_QUANTITY } from '../constants'
// utils
import {
	is,
	notIs,
	LAST_DAY,
	getStatus,
	FIRST_DAY,
	calculateTotalQuantity,
	calcItemsTotalWeightWithoutQty
} from '../utils'
// //////////////////////////////////////////////////

const Event = types.model('Event', {
	guid: types.identifier,
	status: '',
	location: types.frozen()
})

const Load = types
	.model('Load', {
		guid: types.identifier,
		firstEvent: types.frozen(Event),
		lastEvent: types.frozen(Event),
		primaryReferenceValue: '',
		lastMessageDate: types.maybeNull(types.string),
		status: '',
		rate: types.frozen({}),
		totalIncome: types.maybeNull(types.number),
		temperatureLow: types.maybeNull(types.number),
		temperatureHigh: types.maybeNull(types.number)
	})
	.views(self => ({
		get getStatus() {
			return getStatus(self.rate, self.status)
		}
	}))

type LoadRequestType = 'MESSAGES' | 'FINANCE' | 'GENERAL'

const ACTIVE_STATUS = [STATUSES.TEL_IN_TRANSIT, STATUSES.TEL_BOOKED]
const LoadsMap = types.optional(types.map(Load), {})

const Loads = types
	.model('Loads', {
		general: LoadsMap,
		messages: LoadsMap,
		finance: LoadsMap,
		telGuid: '',
		declineState: '', // TODO: Move to Load model this props
		acceptState: '', // TODO: Move to Load model this props
		addStatusState: '', // TODO: Move to Load model this props
		detail: types.frozen(),
		totalRate: types.maybeNull(types.number)
	})
	.actions(self => ({
		clearList(key: string) {
			// TODO: check type here and update
			// self[key].length = 0
			self[key] = {}
			self.totalRate = 0
		},

		getList: flow(function*(
			requestType: LoadRequestType,
			config = {
				fromDate: format(FIRST_DAY, 'MM/DD/YYYY'),
				limit: 30,
				toDate: format(LAST_DAY, 'MM/DD/YYYY')
			},
			offset = 0
		) {
			const { auth } = getRoot(self)
			const { http } = getEnv(self)
			const response = yield http.post(
				`https://route.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/tel/list/forMobile`,
				{
					driverGuid: auth.driver_guid,
					requestType,
					...config,
					offset
				}
			)
			if (response.ok) {
				const { tels, totalIncome } = yield response.json()
				if (isEmpty(tels)) return
				const pending = tels.filter(
					({ rate }) => rate && is(rate.status, STATUSES.RATE_PENDING)
				)
				const booked = tels.filter(({ status }) => is(status, STATUSES.TEL_BOOKED))
				const other = tels.filter(
					({ rate, status }) =>
						rate &&
						notIs(rate.status, STATUSES.RATE_PENDING) &&
						notIs(status, STATUSES.TEL_BOOKED)
				)
				;[...pending, ...booked, ...other].forEach(tel =>
					self[requestType.toLowerCase()].put(tel)
				)
				self.totalRate = totalIncome
			}
		}),
		getDetail: flow(function*(guid: string) {
			const { http } = getEnv(self)
			const response = yield http.get(
				`https://route.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/tel/${guid}/details/forMobile`
			)
			if (response.ok) {
				const data = yield response.json()
				// TODO: Refactor, make detail as types model, not as types.frozen and move to better place
				self.detail = data
			}
		}),
		// TODO: Move this actions to Load model
		accept: flow(function*() {
			const { http } = getEnv(self)
			const { guid } = self.pending
			self.acceptState = 'pending'
			const { ok } = yield http.put(
				`https://route.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/tel/rate/${guid}/accept`
			)
			if (ok) {
				self.getDetail(self.telGuid)
				self.acceptState = 'success'
				// TODO: change store without call API
				self.getList('GENERAL')
			}
		}),
		// TODO: Move this actions to Load model
		decline: flow(function*(reasonGuid) {
			const { http } = getEnv(self)
			const { guid } = self.pending
			self.declineState = 'pending'
			const params = notIs(reasonGuid, 'Reason')
				? { declineReasonCodeGuid: reasonGuid }
				: undefined
			const { ok } = yield http.put(
				`https://route.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/tel/rate/${guid}/decline`,
				undefined,
				{ params }
			)
			if (ok) {
				self.getDetail(self.telGuid)
				self.declineState = 'success'
				// TODO: Server must return next status
				// use GET /tel/{guid}/status to get load status
				// TODO, change store without call API
				self.getList('GENERAL')
			}
		}),

		getStatus: flow(function*(guid: string) {
			const { http } = getEnv(self)
			const response = yield http.get(
				`https://route.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/tel/${guid}/status`
			)
			if (response.ok) {
				self.pending.status = yield response.json()
			}
		}),

		// TODO: Move this actions to Load model
		sendStatus: flow(function*(body) {
			const { http } = getEnv(self)
			const { auth } = getRoot(self)
			self.addStatusState = 'pending'
			const { ok } = yield http.post(
				`https://route.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/tel/statusMessage`,
				{
					...body,
					driverGuid: auth.driver_guid,
					loadGuid: self.telGuid
				}
			)
			if (ok) {
				self.addStatusState = 'success'
				// TODO: change store without call API
				self.getList('GENERAL')
			}
		}),

		// TODO: Move this actions to Load model
		sendCheckInStatus: flow(function*(body) {
			const { http } = getEnv(self)
			self.addStatusState = 'pending'
			const { ok } = yield http.post(
				`https://fleet.amoustms.${
					process.env.REACT_APP_BASE_API_DOMAIN
				}/fleet/driver/latestLocation/checkIn`,
				body
			)
			if (ok) self.addStatusState = 'success'
		}),

		clearModalState(keyState) {
			self[keyState] = ''
		},

		setTelGuid(guid: string) {
			self.telGuid = guid
		}
	}))
	.views(self => ({
		get active() {
			return (
				values(self.general).find(load => ACTIVE_STATUS.includes(load.status)) ||
				self.activeDetail
			)
		},

		get activeDetail() {
			if (self.detail) {
				const status = getStatus(self.detail.rate, self.detail.status)
				return ACTIVE_STATUS.includes(status) ? self.detail : undefined
			}
			return undefined
		},

		get pending() {
			if (is(self.detail.rate.status, STATUSES.RATE_PENDING))
				return self.detail.rate
		},

		get selectedRate() {
			if (self.detail.rate.selected) return self.detail.rate
			return null
		},

		get totalWeight() {
			return self.detail.events.reduce(
				(sum, event) => {
					if (notIs(event.eventType, 'pickup') || isEmpty(event.items)) return sum
					const stopParams = calcItemsTotalWeightWithoutQty(event.items)
					return {
						weight: add(sum.weight, stopParams[FIELD_NAME_WEIGHT]),
						type: stopParams[FIELD_NAME_WEIGHT_TYPE]
					}
				},
				{ weight: '', type: '' }
			)
		},

		get totalQuantity() {
			return self.detail.events.reduce(
				(sum, event) => {
					if (notIs(event.eventType, 'pickup') || isEmpty(event.items)) return sum
					const stopParams = calculateTotalQuantity(event.items)
					return {
						count: add(sum.count, stopParams[FIELD_NAME_QUANTITY]),
						type: stopParams[FIELD_NAME_PACKAGE_TYPE]
					}
				},
				{ count: '', type: '' }
			)
		},

		get load() {
			return self.detail.primaryReference
		}
	}))

export { Loads }
