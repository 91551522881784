import styled from 'styled-components'
// ui
import { Text } from '../core'
// //////////////////////////////////////////////////

const ListItem = styled.article`
	display: flex;
	padding-left: 10px;
	padding-right: 10px;
	align-items: center;
	border-bottom: solid 1px #d8d8d8;

	> svg {
		min-width: 50px;
	}
`

const ListItemAddress = styled(Text)`
	color: ${props => props.theme.textColor2};
`

const ListItemAddressWithEllipsis = styled(Text)`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: ${props => props.theme.textColor2};
	max-width: 100%;
`

const ListItemTitle = styled(Text)`
	word-break: break-all;
	color: ${props => props.theme.textColor6};
	max-width: 100%;
`

const LoadTimeWrapper = styled.div`
  font-size: 10px;
`

const LoadTime = styled.time`
	font-size: 10px;
	max-width: 80px;
	font-weight: normal;
	color: ${props => props.theme.textColor2};
`

export {
	LoadTime,
	ListItem,
	ListItemTitle,
	ListItemAddress,
	LoadTimeWrapper,
	ListItemAddressWithEllipsis
}
