import React from 'react'
import styled from 'styled-components/macro'
import T from 'prop-types'

import { notIs } from '../../utils'

const Checkbox = styled.input.attrs(() => ({ type: 'checkbox' }))`
	position: absolute;
	margin-left: -9999px;
	visibility: hidden;
`

const Div = styled.div`
	position: relative;
	line-height: 100%;
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	box-sizing: border-box;

  label {
    cursor: pointer;
  }

  ${Checkbox} {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }

  > label:first-child {
    display: inline-block;
    margin: 0 6px 0 0;
  }

  label + label {
    margin-left: 6px;
  }
  
  ${Checkbox} + label {
    display: inline-block;
    position: relative;
    margin: 0;
    outline: none;
    user-select: none;
    box-sizing: border-box;
    padding: 1px;
    width: 48px;
    height: 24px;
    vertical-align: middle;
    background-color: #b1b1b1;
    border-radius: 60px;
    transition: background 0.4s;
  }

	${Checkbox} + label:after,
	${Checkbox} + label:before {
    display: inline-block;
    position: absolute;
    content: '';
  }

	${Checkbox} + label:before {
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    background-color: ${props => props.theme.bgc4};
    border-radius: 60px;
    transition: background 0.4s;
  }

	${Checkbox} + label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 16px;
    background-color: #b1b1b1;
    border-radius: 52px;
    transform: translateX(0);
    transition: transform 0.4s, background 0.4s;
  }

	${Checkbox}:checked + label {
    background-color: #930000;
  }

	${Checkbox}:checked + label:after {
    transform: translateX(24px);
    background-color: #930000;
  }
`

type Props = React.InputHTMLAttributes<HTMLInputElement>

export function Toggle({ name, defaultValue, value, onChange }: Props) {
	const checkbox = notIs(defaultValue, undefined)
		? { defaultValue }
		: { checked: value, onChange }

	return (
		<Div>
			{/* {label && <label htmlFor={name}>{label}</label>} */}
			<Checkbox {...checkbox} id={name} name={name} />
			<label htmlFor={name} />
			{/* {labelRight && <label htmlFor={name}>{labelRight}</label>} */}
		</Div>
	)
}

Toggle.propTypes = {
	name: T.oneOfType([T.string, T.number]).isRequired,
	defaultValue: T.bool,
	value: T.bool,
	onChange: T.func
}
